import React from 'react';
import PropTypes from 'prop-types';
import { SYMBOLS } from 'Services/session/core__session.utils';

const defaultSymbol = '&#x2715;';

/**
 * this component contains the table for each category
 * @param {string} symbol string of cross icon code
 */

export const CrossIcon = ({ symbol }) => {
  return symbol ? (
    <span>{SYMBOLS[symbol]}</span>
  ) : (
    <span
      dangerouslySetInnerHTML={{
        __html: defaultSymbol,
      }}
    />
  );
};

CrossIcon.propTypes = {
  symbol: PropTypes.string,
};
