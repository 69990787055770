import { useEffect } from 'react';
import PubSub from 'Services/pubsub/core__pubsub';

const usePubSub = (pubSubEventName, callback) => {
  useEffect(() => {
    const subscription = PubSub.listen(pubSubEventName, callback);
    return () => subscription.unsubscribe();
  }, [pubSubEventName, callback]);
};

export default usePubSub;
