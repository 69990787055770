import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import project from '../../project';
/**
 *
 * @param {string} url
 */
// lastrout parametar is used to pass backup link to navigation handler in case that there is no more pages we can just pass
// to function new route and we have to pass boolan true also in order to activate lastRoute Parameter, sportsbook is usually default route
// that used on other projects so we cannot change but we can update it.

const redirectRoute = project.home || '/sportsbook/';

export const handleBackNavigation = (url, lastRoute, bool, backUrl) => {
  if (url && JSON.parse(url).length > 1) {
    url = JSON.parse(url);
    url.pop();
    window.sessionStorage.setItem('root', JSON.stringify(url));
    window.location.href = url[0];
  } else if (url && JSON.parse(url).length === 1) {
    url = JSON.parse(url);
    url.pop();
    window.sessionStorage.setItem('root', JSON.stringify(url));
    window.location.href = !bool ? redirectRoute : lastRoute;
  } else if (backUrl && !url) {
    PubSub.emit(PubsubEvents.PARENT_NAVIGATION, backUrl);
  }
};
