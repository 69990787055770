import defaults from '../../project';
import { getCookie } from 'Services/cookie/core__cookies';
import { useEffect, useState } from 'react';
import axios from 'axios';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import useScript from '../hooks/core__useScript';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';

const useCaptainUpService = () => {
  const [signed_usertoken, setToken] = useState('');
  const [captainData, setCaptain] = useState(null);
  const status = useScript('https://captainup.com/assets/sdk.js');

  const callDatas = async customerData => {
    let apiKey = defaults.captainup_api_key;
    let clientToken = defaults.captainup_client_token;
    if (window?.location?.host === 'olybet.sk') {
      apiKey = document.body.dataset?.captainupapikey;
      clientToken = document.body.dataset?.captainupclienttoken;
    }
    const customer = customerData?.customerOBJ?.raw;
    if (!apiKey) return;
    if (!customer?.accessToken) return;
    try {
      const { data } = await axios.post(
        `/fsb-api-rest/captainUp/authenticate.json?access_token=${customer?.accessToken}`
      );
      setToken(data?.signed_usertoken);
    } catch (err) {
      //err
    }

    const lang = getCookie('localeKey')?.replace('_', '-').toLowerCase();
    const config = {
      api_key: apiKey,
      lang: lang,
      signed_user: signed_usertoken,
      user: {
        id: customer?.id,
        name: customer?.firstName,
        sign_timestamp: Date.now(),
      },
      client_token: clientToken,
    };
    try {
      await window.captain?.up(config);
      const AllData = await window.captain;
      const userList = await window.captain?.user.get(); //get user List
      const levels = await window.captain?.levels.getList(); //get Levels List
      const userLevel = await window.captain?.levels.get(); //get Levels List
      const userCurrency = await window.captain?.currencies.get(); //gets user currencies
      const toArray = Object.entries(userList?.badge_progress);
      const getShopItems = await window.captain?.assets.getList(); //to get shop items
      const purchaseItems = window.captain?.assets?.acquire;
      const Badges = await window.captain?.badges.getList(); //to get badges
      const getBadges = getBadgesList(Badges, userList); //get active badges
      const getTrophies = getTrophiesList(Badges, userList); // get Trophies
      const CollectedCaptainData = toArray?.map(a => {
        return { progress: a[1], data: Badges?.find(b => a[0] === b?._id) };
      });
      setCaptain({
        CollectedCaptainData: CollectedCaptainData,
        allCaptainData: AllData,
        getUserList: userList,
        getLevels: levels,
        getShopItems: getShopItems,
        getBadges: getBadges,
        getTrophies: getTrophies,
        purchaseItems: purchaseItems,
        userLevel: userLevel,
        userCurrency: userCurrency,
        getAllBadges: Badges,
      });
    } catch (err) {
      //err
    }
  };

  useEffect(() => {
    if (status === 'ready') {
      callDatas(FSBCustomerSession);
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const create = PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
      callDatas(FSBCustomerSession);
    });
    const update = PubSub.listen(PubsubEvents.SESSION_UPDATED, () => {
      callDatas(FSBCustomerSession);
    });
    return () => {
      create && create.unsubscribe();
      update && update.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getBadgesList = (Badges, userList) => {
    const activeBadgesList = Badges.filter(badgeData => {
      return badgeData.custom.badge === 'TRUE';
    });
    const badge_progress = Object.entries(userList.badge_progress);
    const achieved_badges = userList.achieved_badges;
    activeBadgesList.forEach(activeBadge => {
      activeBadge.badge_progress = badge_progress.find(
        progress => progress[0] === activeBadge.id
      );
      activeBadge.achieved_badges = achieved_badges.find(
        achieved => achieved?.id === activeBadge?.id
      );
    });
    let sortedBadges = activeBadgesList;
    if (activeBadgesList.length > 1) {
      sortedBadges = activeBadgesList.sort(function (a, b) {
        return new Date(b?.created_at) - new Date(a?.created_at);
      });
    }
    return sortedBadges;
  };
  const getTrophiesList = (Badges, userList) => {
    const activeTrophiesList = Badges.filter(badge => {
      return badge.custom.trophy === 'TRUE';
    });
    const badge_progress = Object.entries(userList.badge_progress);
    const achieved_badges = userList.achieved_badges;
    activeTrophiesList.forEach(activeTrophy => {
      activeTrophy.badge_progress = badge_progress.find(
        progress => progress[0] === activeTrophy.id
      );
      activeTrophy.achieved_badges = achieved_badges.find(
        achieved => achieved?.id === activeTrophy?.id
      );
    });
    let sortedTrophies = activeTrophiesList;
    if (activeTrophiesList.length > 1) {
      sortedTrophies = activeTrophiesList.sort(function (a, b) {
        return new Date(b?.created_at) - new Date(a?.created_at);
      });
    }
    return sortedTrophies;
  };

  return captainData;
};

export default useCaptainUpService;
