import { Subject, fromEvent, BehaviorSubject } from 'rxjs';

class EventEmitter {
  constructor() {
    this.subjects = {};
  }
  emit(name, data) {
    const fnName = this.createName(name);
    this.subjects[fnName] || (this.subjects[fnName] = new Subject());
    this.subjects[fnName].next(data);
  }
  listen(name, handler) {
    const fnName = this.createName(name);
    this.subjects[fnName] || (this.subjects[fnName] = new Subject());
    return this.subjects[fnName].subscribe(handler);
  }
  unsubscribe(subscription) {
    const subject = subscription._s;
    const observable = subscription._o;
    const arrayWithoutObservable = subject.observers.filter(
      o => o !== observable
    );

    if (arrayWithoutObservable.length === subject.observers.length) return;

    subject.observers = arrayWithoutObservable;
    observable.onCompleted();
  }
  close(name) {
    const channel = this.subjects[this.getName(name)];
    if (channel) {
      channel.complete(); //onCompleted was called earlier
    }
  }
  removeAllObservesFromChannel(name) {
    const channel = this.subjects[this.getName(name)];

    if (channel) {
      channel.observers = [];
    }
  }
  createName(name) {
    return `fsb_pubSub_${name}`;
  }
  getName(name) {
    return `fsb_pubSub_${name}`;
  }

  emitBehavior(name, data) {
    const fnName = this.createName(name);
    this.subjects[fnName] || (this.subjects[fnName] = new BehaviorSubject());
    this.subjects[fnName].next(data);
  }
  listenBehavior(name, handler) {
    const fnName = this.createName(name);
    this.subjects[fnName] || (this.subjects[fnName] = new BehaviorSubject());
    return this.subjects[fnName].subscribe(handler);
  }
}

class Api {
  constructor() {
    this.globalEventEmitter$ = new EventEmitter();
  }
  // API Public Methods
  emit(name, data) {
    this.globalEventEmitter$.emit(name, data);
  }

  emitBehavior(name, data) {
    this.globalEventEmitter$.emitBehavior(name, data);
  }

  removeAllObservesFromChannel(name) {
    this.globalEventEmitter$.removeAllObservesFromChannel(name);
  }

  listen(name, handler) {
    return this.globalEventEmitter$.listen(name, handler);
  }

  listenBehavior(name, handler) {
    return this.globalEventEmitter$.listenBehavior(name, handler);
  }

  unsubscribe(subscription) {
    this.globalEventEmitter$.unsubscribe(subscription);
  }
  get observable() {
    return fromEvent;
  }
  createEventEmitter() {
    return new EventEmitter();
  }
}

const singleton = new Api();

export default singleton;
