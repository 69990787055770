//Method to apply window height and margin to the main-wrapper
//margin-top of main-wrapper is same as header height
import { PubsubEvents } from '../pubsub/core__pubsub.constants';
import PubSub from '../pubsub/core__pubsub';

import {
  BREAKPOINT_XL,
  BREAKPOINT_L,
  BREAKPOINT_M,
  BREAKPOINT_S,
} from 'UI/globals/breakpoints';
import { BREAKPOINT_XS } from 'CORE__UI/globals/core__breakpoints';
import { GLOBAL_CONSTANTS } from '../global/core__constants';

// currentBreakPoint should be updated every time orientation changes or resize events happen
let currentBreakPoint = __getBreakPoint();

export const applyWrapperStyle = () => {
  const newBreakpoint = __getBreakPoint();

  if (currentBreakPoint !== newBreakpoint) {
    PubSub.emit(PubsubEvents.BreakPoint, newBreakpoint);
    currentBreakPoint = newBreakpoint;
  }
};

try {
  if (!window.location.pathname.match('/welcome/')) {
    applyWrapperStyle();
    window.addEventListener('resize', () => {
      applyWrapperStyle();
    });
  }
} catch (error) {
  //no handling required
}
try {
  if (
    window.location.pathname.match('/betting-pools/') ||
    window.location.pathname.match('/casino/') ||
    window.location.pathname.match('/promotions/') ||
    window.location.pathname.match('/my-account/')
  ) {
    applyWrapperStyle();
    hideCategoryMenuList();
    window.addEventListener('resize', () => {
      applyWrapperStyle();
      hideCategoryMenuList();
    });
  }
} catch (error) {
  //no handling required
}

export function __getBreakPoint() {
  try {
    if (typeof window === 'object') {
      const innerWidth = window.innerWidth;
      if (parseInt(BREAKPOINT_XL) <= innerWidth) {
        return 'BREAKPOINT_XL';
      } else if (
        parseInt(BREAKPOINT_L) <= innerWidth &&
        innerWidth < parseInt(BREAKPOINT_XL)
      ) {
        return 'BREAKPOINT_L';
      } else if (
        parseInt(BREAKPOINT_M) <= innerWidth &&
        innerWidth < parseInt(BREAKPOINT_L)
      ) {
        return 'BREAKPOINT_M';
      } else if (
        parseInt(BREAKPOINT_S) <= innerWidth &&
        innerWidth < parseInt(BREAKPOINT_M)
      ) {
        return 'BREAKPOINT_S';
      } else if (
        parseInt(BREAKPOINT_XS) <= innerWidth &&
        innerWidth < parseInt(BREAKPOINT_S)
      ) {
        return 'BREAKPOINT_XS';
      } else if (innerWidth < parseInt(BREAKPOINT_XS)) {
        return 'BREAKPOINT_XS';
      }
    }
  } catch (error) {
    //no handling required
  }
}

//Method to highlight header navigational tabs
export const highlightHeaderTabs = () => {
  const currentPath = window.location.pathname;
  document.querySelectorAll('.topheader-middle--link')?.forEach(element => {
    if (currentPath === element.pathname) {
      element.classList.add('active');
    } else if (
      //underlining current virtuals paths
      currentPath.startsWith('/virtual/') &&
      element.pathname.includes('/virtual/')
    ) {
      element.classList.add('active');
    }
    if (currentPath.startsWith('/casino/')) {
      element.classList.add('active-casino');
    }
  });
  // additional section for addtional header for quinn
  if (document.querySelector(`[href$="${window.location.pathname}"]`)) {
    document
      .querySelector(`[href$="${window.location.pathname}"]`)
      .classList.add('active');
  }
  const path = window.location.pathname?.split('/')[1];
  // additional section for addtional header for FITZ
  if (
    document.querySelector(`[href$="/${path}/"]`) &&
    path !== GLOBAL_CONSTANTS.S_CASINO
  ) {
    document.querySelector(`[href$="/${path}/"]`).classList.add('active');
  }
};

try {
  highlightHeaderTabs();

  window.addEventListener(
    'orientationchange',
    function () {
      applyWrapperStyle();
    },
    false
  );
} catch (error) {
  //no handling required
}

export const welcomeScreen = () => {
  if (window.location.pathname.match('/welcome/')) {
    if (document.querySelector('.topheader')) {
      document.querySelector('.topheader').style.display = 'none';
      document
        .querySelector('.main-middle')
        .setAttribute('style', `margin: 0px; padding: 0px;`);
    }
  }
};

export function hideCategoryMenuList() {
  if (document.querySelector('.main-left-wrapper')) {
    const innerWidth = window.innerWidth;
    if (innerWidth > parseInt(BREAKPOINT_S)) {
      document.querySelector('.main-left-wrapper').style.display = 'none';
    } else {
      document.querySelector('.main-left-wrapper').style.display =
        'block !important';
    }
  }
}

try {
  welcomeScreen();
} catch (error) {
  //no handling required
}
