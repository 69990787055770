import React from 'react';
import PropTypes from 'prop-types';
import { SYMBOLS } from 'Services/session/core__session.utils';
import client from '../../project';

const defaultSymbol = client.defaultCurrency;

/**
 * this component contains the table for each category
 * @param {*} symbol string of currency code
 */

export const CurrencyFormat = (symbol, showCurrency = false) => {
  if (symbol && showCurrency) {
    return SYMBOLS[symbol];
  }
  return symbol ? (
    <span>{SYMBOLS[symbol]}</span>
  ) : (
    <span>{SYMBOLS[defaultSymbol]}</span>
  );
};
CurrencyFormat.propTypes = {
  symbol: PropTypes.string,
  text: PropTypes.string,
};

/**
 * this function used to make currency dynamic
 * @param {*} symbol string of currency code
 * @param {*} text string of text which contain the dynamic currency string
 */
export const HanldeCMSCurrencySymbol = (text, symbol) => {
  return text ? text?.replace(/{{currencyCode}}/g, symbol) : ' ';
};
/**
 * this function returns the default currency
 */
export const HnaldeDefaultCurrency = () => {
  let defaultCurrency = client?.defaultCurrency || 'GBP';
  if (typeof defaultCurrency === 'object') {
    defaultCurrency = defaultCurrency[0]['label'];
  }
  return defaultCurrency;
};

export const formatCurrency = (
  amount = 0,
  { currencyCode = client.currency, abs = false } = {}
) => {
  const symbol = SYMBOLS[currencyCode];
  const value = abs ? Math.abs(Number(amount)) : Number(amount);
  return `${symbol} ${value.toFixed(2)}`;
};

// Function to add commas in cash boexes. Requested by KM, going to be made optional
// due scope of this being used around the codebase and not wanting to mess it up for
// Other brands

export const formatCurrencyWithCommas = (
  amount = 0,
  { currencyCode = client.currency, abs = false } = {}
) => {
  const symbol = SYMBOLS[currencyCode];
  const value = abs ? Math.abs(Number(amount)) : Number(amount);
  return `${symbol} ${value.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};
