export const PubsubEvents = {
  BetslipUpdate: 'ws:betslip:update',
  BetslipDelete: 'ws:betslip:delete',
  EventsAppBetslipUpdate: 'ws:betslip:events:update',
  EventHeaderUpdate: 'ws:eventHeader:update',
  EventsAppActive: 'ws:events:setactive',
  CallLoginPopUp: 'ws:betslip:login',
  CallBetSlip: 'ws:login:betslip',
  callJoinNowPopUp: 'ws:login:joinnow',
  openLoginModal: 'ws:auth:login',
  openRegisterModal: 'ws:auth:register',
  openMyAccount: 'ws:account:get',
  UpdateCategoryListSelection: 'categoryListApp:update-category-list-selection',
  openPasswordModal: 'ws:recover:password',
  windowResize: 'ws:window:resize',
  oddsValue: 'ws:oddsSelection:change',
  callLoginModalFromCasino: 'ws:casino:login',
  callCasinoGame: 'ws:login:casino',
  postMarketIdToWS: 'ws:sub:market',
  getMarketDataToWS: 'ws:market',
  getEvent: 'ws:event',
  UpdateBalanceWithData: 'ws:update:user:balance',
  PAYMENT_IQ_DATA: 'app:paymentiq',
  PAYMENT_IQ_START: 'app:paymentiq:start',
  hideEventHeaders: 'ws:eventHeader:hide',
  hideMarketDropdown: 'ws:marketDropdown:hide',
  SESSION_CREATED: 'session:created',
  SESSION_UPDATED: 'session:updated',
  SESSION_DESTROYED: 'session:destroyed',
  SESSION_EXPIRED: 'session:expired',
  ADDRESS_NOT_FOUND: 'address:not:found',
  UPDATE_BALANCE_IQ: 'update:balance',
  CALL_MARKETS_APP: 'callingMarketsApp',
  openModals: 'callsPopUpOnclick',
  OpenResponsibleModalPopUpLogin: 'TriggersOnLogin',
  CaptainData: 'ShareCaptainData',
  REALITY_CHECK_UPDATED: 'reality:check:updated',
  GAME_DATA: 'gameData',
  REMOVE_LOGIN_MODEL: 'ws:remove:login:model',
  LANGUAGE_UPDATE: 'languageUpdate',
  CATEGORY_UPDATE: 'ws:category:updated',
  CUSTOMER_API_DATA: 'customerDataCalled',
  CLOSE_CATEGORY_LIST: 'ws:close:category:list',
  BOTTOM_NAV_DATA: 'ws:bottomNavData',
  BETSLIP_EXPAND: 'ws:betslipExpand',
  //LOTTO EVENTS
  LOTTO_HEADER_UPDATE: 'ws:lottoheader:update',
  LOTTO_ACTIVE_HEADER: 'LOTTO_ACTIVE_HEADER',

  // SESSION EVENTS
  SESSION_CREATE: 'session:new',
  SESSION_UPDATE: 'session:update',
  SESSION_DESTROY: 'session:destroy',
  SESSION_NOSESSION: 'session:nosession',
  IFRAME_ORIENTATION: 'iframe:orientation',
  IFRAME_GAME_ORIENTATION: 'iframe:game:orientation',

  BreakPoint: 'BreakPoint',

  // update header when betslip post successfully
  UPDATE_WALLET: 'ws:wallet:update',
  CLOSE_RECEIPT: 'ws:close:receipt',
  OPEN_BANKING: 'open:banking',
  KEYBOARD_INPUT: 'onscreen:keyboard:input',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',

  // osiros
  CARD_INSERTED: 'osiros:cardNumberRecieved',

  // create a bet
  addBetBuilder: 'betBulder:add',

  // open quick Deposit
  OPE_QD: 'open:quickdeposit',

  // card details
  CUS_CARD: 'customer:card',

  // BuilderBetUpdate
  BUILDER_BET_UPDATE: 'builder:bet:update',

  // BuilderBetAvailable to show menu in markets app
  BUILDER_BET_AVAILABLE: 'builder:bet:available',
  BUILDER_BET_SELECTED: 'builder:bet:selected',

  // Update balance from WS
  REQUEST_BALANCE_FROM_WS: 'ws:sub:balance',
  GET_UPDATE_BALANCE_FROM_WS: 'ws:balance',
  CUSTOMER_BALANCE_UPDATE: 'ws:balance',
  ACCOUNT_TABS: 'ACCOUNT_TABS',

  // to update user changed theme
  THEME_CHANGED: 'theme:changed',

  // StatScore
  STAT_SCORE_REMOVE_TRACKER: 'statscore:tracker:remove',

  DisplayNotFound: 'DisplayNotFound',
  VirtualEventMarket: 'virtual:market',
  SOCKET_MARKET_SUBSCRIBER: 'ws:sub:market',
  SOCKET_MARKET_LISTEN: 'ws:market',

  SOCKET_EVENT_SUBSCRIBER: 'ws:sub:event',
  SOCKET_EVENT_LISTEN: 'ws:event',

  // GTM Events
  BING_CONVERSION_FIRED: 'bingConversion',
  THIRD_PARTY_TRACKING: '3rd:party:tracking:events',
  GEOCOMPLY_EVENT: 'geocomply:state:change',
  // id comply
  OPEN_ID_COMPLY: 'OPEN_ID_COMPLY',

  // USER LAST LOGGED IN
  LAST_LOGGED_IN: 'user:lastlogin',

  // BET GENIUS TRACKING
  BET_GENIUS_TAG: 'betGenius',
  APP_CONFIG: 'APP_CONFIG',
  BET_COUNT: 'BET_COUNT',
  OPEN_BETSLIP: 'OPEN_BETSLIP',
  CLOSE_BETSLIP: 'CLOSE_BETSLIP',
  BET_COUNTS: 'BET_COUNTS',
  SCROLL_IFRAME: 'SCROLL_IFRAME',
  SHOW_OUTRIGHTS: 'event:show:outrightsapp',
  SCROLL_UP: 'SCROLL_UP',
  SCROLL_LOCK: 'SCROLL_LOCK',
  OPEN_DEPOSIT: 'OPEN_DEPOSIT',
  RESET_HEIGHT: 'RESET_HEIGHT',
  RESET_IFRAME_HEIGHT: 'RESET_IFRAME_HEIGHT',
  SESSION_IFRAME_FAILED: 'SESSION_IFRAME_FAILED',

  // PARENT NAVIGATION
  PARENT_NAVIGATION: 'PARENT_NAVIGATION',

  // UPDATE VERIFICATION POPUP
  SHOW_VERIFICATION_POPUP: 'SHOW_VERIFICATION_POPUP',
  SHOW_REGISTRATION_THIRD_STEP: 'SHOW_REGISTRATION_THIRD_STEP',

  // SET ACCOUNT STATUS
  SET_ACCOUNT_STATUS: 'SET_ACCOUNT_STATUS',
  FORCE_PAGE_SCROLL: 'FORCE_PAGE_SCROLL',
  RESET_BETSLIP_VIEW: 'RESET_BETSLIP_VIEW',
  SET_CONTENT_HEIGHT: 'SET_CONTENT_HEIGHT',

  // HIDE SUBCATEGORY TITLE
  hideSubcategoryTitle: 'subcategoryTitle:hide',

  // HIO additional detail
  ADDITIONAL_DETAIL_REQUIRED: 'additional:detail:required',
  HIO_LOGIN_SUCCESS: 'hio:login:success',

  // SHOW LIVE CHAT
  SHOW_LIVECHAT: 'SHOW_LIVECHAT',

  // MOBILE NAVIGATION APP
  GAME_INFO: 'sidebar:gameinfo',
  LOYALTY_INFO: 'captainup:sidebar:inprogress',

  // APPLY_CASINO_FILTER
  APPLY_CASINO_FILTER: 'APPLY_CASINO_FILTER',

  GEO_SUCCESS: 'GEO_SUCCESS',
  IP_CHANGED_EVENT: 'IP_CHANGED_EVENT',
  OPEN_CALLPAY_IFRAME: 'OPEN_CALLPAY_IFRAME',
  RELOAD_DEPOSITS: 'RELOAD_DEPOSITS',

  // ROTATE ICON
  ICON_ROTATE: 'ICON_ROTATE',

  // BANKING TO PAYMENTS APP COMUNICATION
  UPDATE_MIN_MAX: 'UPDATE_MIN_MAX',
  WITHDRAW_STATUS: 'WITHDRAW_STATUS',

  // PAYMENTS APP TO BANKING COMUNICATION
  UPDATE_CARD: 'UPDATE_CARD',
  UPDATE_AMOUNT: 'UPDATE_AMOUNT',
  TRIGGER_WITHDRAW: 'TRIGGER_WITHDRAW',

  // UPDATING CHALLENGES
  UPDATE_CHALLENGES: 'UPDATE_CHALLENGES',

  SEND_SELECTED_TAB: 'SEND_SELECTED_TAB',

  // DISABLE LOADER
  DISABLE_LOADER: 'DISABLE_LOADER',

  //MFA Modal Pubsubs
  StartMfaApp: 'START_MFA_APP',
  FinishMfaApp: 'FINISH_MFA_APP',

  //Kyc Popup Pubsubs
  StartKycPopupApp: 'START_KYC_POPUP_APP',

  ChatWithBroker: 'CHAT_WITH_BROKER',

  UPDATE_IDENTITY_TYPE: 'UPDATE_IDENTITY_TYPE',

  // REMOVE_AMOUNT_SEC
  REMOVE_AMOUNT_SEC: 'REMOVE_AMOUNT_SEC',
  CALLPAY_ERROR: 'CALLPAY_ERROR',

  ODDS_MOVEMENT_TOGGLE: 'ODDS_MOVEMENT_TOGGLE',

  //Document Upload App
  DOCUMENT_UPLOADED: 'DOCUMENT_UPLOADED',

  //IBAN withdraw response
  WITHDRAW_RESPONSE_IBAN: 'WITHDRAW_RESPONSE_IBAN',

  // Close account reason
  CLOSE_ACCOUNT_REASON: 'CLOSE_ACCOUNT_REASON',

  // update price
  PRICE_NOT_CURRENT: 'PRICE_NOT_CURRENT',

  //for checking payment expiry
  PAYMENT_EXPIRED: 'PAYMENT_EXPIRED',

  //show payment expiry popup
  PAYMENT_EXPIRE_POPUP: 'PAYMENT_EXPIRE_POPUP',
};
