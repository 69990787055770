export const header_button_status = {
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED',
};

export const event_status = {
  LIVE: 'IN_PLAY',
  UPCOMING: 'SCHEDULED',
  FEATURED: 'FEATURED',
  COUPONS: 'COUPONS',
  LEAGUES: 'LEAGUES',
};
export const LocalStorage = {
  selectionid_uk: 'selectionid_uk',
};

export const Constants = {
  UpdateBetslipsSelection: 'updateBetslipsSelection',
  DEFAULT_COLUMNS: 2,
  DEFAULT_COLUMNS_AMERICAN: 3,
  MONEY_LINE: 'Money Line',
  SPREAD: 'Spread',
  SET_WINNER: 'SET_WINNER',
  TOTAL: 'Total',
  OVER: 'OVER',
  UNDER: 'UNDER',
  ODDS_INCREASE: 'increase',
  ODDS_DECREASE: 'decrease',
  DECIMAL: 'decimal',
  FRACTIONAL: 'fractional',
  AMERICAN: 'american',
  TENNIS_REF: 'TENNIS',
  TENNIS_CAT: 'Tennis',
  SOCCER_REF: 'SOCCER',
  AMERICAN_FOOTBALL_REF: 'AMERICAN_FOOTBALL',
  BASKETBALL_REF: 'BASKETBALL',
  VOLLEYBALL_REF: 'VOLLEYBALL',
  DARTS_REF: 'DARTS',
  TABLE_TENNIS_REF: 'TABLE_TENNIS',
  ICE_HOCKEY_REF: 'ICE_HOCKEY',
  THREEBALL: '3BALL',
  TWOBALL: '2BALL',
  HOME: 'HOME',
  DRAW: 'DRAW',
  AWAY: 'AWAY',
  SHC: 'SHC',
  YES: 'YES',
  NO: 'NO',
  DOUBLE_CHANCE: 'DC',
  EITHER: 'EITHER',
  CRICKET_REF: 'CRICKET',
  DECIMAL_PROVIDER: 'DECIMAL',
};

export const SH_MARKETS = [
  'OU',
  'TGOU',
  'SHC',
  'SHC_LS',
  'MTGOU',
  'RMLOU',
  'EAH',
  'OPAR',
  'TPM',
  'MTL_OU',
  'SHC_NT',
  'HRML',
  'SHC_S',
  'AH',
];

export const SH_SELECTIONS = { OVER: 'Over', UNDER: 'Under' };
export const SH_SELECTIONS_REVERSE = { UNDER: 'Under', OVER: 'Over' };
