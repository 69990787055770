class ScDecoder {
  constructor(data) {
    const SCHEMA_KEY = '__s';
    const schema = data.__s || {};

    function decodeArray(array) {
      let result = {};

      if (array.length === 0) {
        return array;
      }

      const schemaKeys = schema[array[0]];

      if (schemaKeys) {
        const keys = schemaKeys.split('|');

        for (let i = 1; i < array.length; i++) {
          result[keys[i - 1]] = decodeObject(array[i]);
        }

        return result;
      } else {
        result = [];

        for (let i = 0; i < array.length; i++) {
          result.push(decodeObject(array[i]));
        }

        return result;
      }
    }

    function decodeObject(object) {
      if (typeof object !== 'object' || !object) {
        return object;
      } else if (Array.isArray(object)) {
        return decodeArray(object);
      } else {
        return decodeMap(object);
      }
    }

    function decodeMap(map) {
      const result = {};
      const keys = Object.keys(map);

      for (let i = 0; i < keys.length; i++) {
        result[keys[i]] = decodeObject(map[keys[i]]);
      }

      return result;
    }

    function decodeImpl(data) {
      const result = {};
      const keys = Object.keys(data);

      for (let i = 0; i < keys.length; i++) {
        if (SCHEMA_KEY !== keys[i]) {
          result[keys[i]] = decodeObject(data[keys[i]]);
        }
      }

      return result;
    }

    return decodeImpl(data);
  }
}

export default ScDecoder;
