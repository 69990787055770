import PubSub from 'Services/pubsub/core__pubsub';
import { useEffect } from 'react';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import useCaptainUpService from '../hooks/core__useCaptainUpService';

export const StartCaptainUpService = () => {
  const captainData = useCaptainUpService();
  useEffect(() => {
    if (captainData) {
      PubSub.emit(PubsubEvents.CaptainData, captainData);
    }
  });
};
