import { useEffect, useState } from 'react';
import useFSBSession from './core__useFSBSession';

export const fwvStates = {
  FALSE: 'FALSE', // default
  PENDING: 'PENDING',
  TRUE: 'TRUE',
};

// first withdrawal redirect hook
// it checks if the "First Withdrawal Verfied" CMS account setting is set to "FALSE" OR "PENDING"
// and redirects to given redirectTo relative url when it does
const useFWVRedirect = ({ redirectTo, enabled = true }) => {
  const data = useFSBSession();
  const [userFwvstatus, setuserFwvstatus] = useState(false);
  useEffect(() => {
    if (!enabled) {
      // when FWV check enable come false
      setuserFwvstatus(true);
    } else {
      // when FWV check enable come true
      const isNotVerified = [fwvStates.FALSE, fwvStates.PENDING].includes(
        data?.firstWithdrawalVerified
      );
      setuserFwvstatus(!isNotVerified);
      if (enabled && isNotVerified) window.location.href = redirectTo;
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return userFwvstatus;
};

export default useFWVRedirect;
