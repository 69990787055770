export const BRAND = {
  primaryD: '#333333',
  primary: '#404040',
  primaryL: '#525252',
  secondaryD: '#DAC201',
  secondary: '#FEE30E',
  secondaryL: '#FFED66',
  tertiaryD: '#15283F',
  tertiary: '#22445D',
  tertiaryL: '#627A97',
  favourite: '#f9d840',
  favouriteL: 'rgba(255, 255, 255, 0.6)',
  highlightD: '#d41219',
  highlight: '#ec1c24',
  highlightL: '#f37277',
};

export const GREYS = {
  black: '#1a1a1a',
  silverD: '#292929',
  silver: '#6b6b6b',
  doveD: '#bbbbbb',
  dove: '#d4d4d4',
  doveL: '#fafafa',
  white: '#ffffff',
  action: '#263238',
};

export const FUNCTIONAL = {
  positiveD: '#185F3E',
  positive: '#36B37E',
  positiveL: '#D8F3E8',
  negativeD: '#732222',
  negative: '#F37277',
  negativeL: '#FEEBEB',
  guideD: '#0F5A80',
  guide: '#4CC4FF',
  guideL: '#EBF8FF',
  warningD: '#C85809',
  warning: '#FF8C21',
  warningL: '#FDDFC9',
  info: '#0c2b3c',
};

export const FONT = {
  dark: '#404040',
  darkMute: '#6b6b6b',
  light: '#ffffff',
  lightMute: '#fafafa',
  primary: '#404040',
  secondary: '#FEE30E',
};

export const SUPPLEMENTARY = {
  yellowD: '#d6af00',
  yellow: '#fdd835',
  yellowL: '#ffefaa',
  casinoD: '#cc5e00',
  casino: '#ff7600',
  casinoL: '#ff9133',
  poolD: '#8e1aad',
  pool: '#b221d8',
  poolL: '#c34ae3',
  black: '#000000',
};

export const SHADOW = '0 0 10px rgba(5, 76, 129, 0.3)';

export const BGI = 'linear-gradient(-14deg,rgba(0,0,0,0.4) 50%,#00e400 100%)';

export const Q_BGI =
  'linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, #000000 100%)';

export const CARD = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: GREYS.silverD,
  // boxShadow: SHADOW,
  borderRadius: 4,
  marginBottom: 16,
};

export const BACKGROUND = {};
