import { timeFormats } from 'Services/global/core__time-formats';
import { detectMob } from 'Services/globalfunctions/core__global-functions';

timeFormats.accounts.primary = 'DD/MM/YYYY, HH:mm';

export default {
  countryCodeArray: [
    { value: 'IP31', label: 'IP31' },
    { value: 'MK45', label: 'MK45' },
    { value: 'SP11', label: 'MSP11' },
    { value: 'GL7', label: 'GL7' },
    { value: 'YO62', label: 'YO62' },
    { value: 'SO51', label: 'SO51' },
    { value: 'YO17', label: 'YO17' },
  ],

  home: '/sportsbook/',
  clientIdDesktopWeb: 'betgoodwinweb',
  clientIdMobileWeb: 'betgoodwinmobile',
  get clientId() {
    try {
      const isMobile = detectMob();
      const clientId = isMobile
        ? this.clientIdMobileWeb
        : this.clientIdDesktopWeb;
      return clientId;
    } catch (e) {
      return this.clientIdDesktopWeb;
    }
  },
  clientSecretDesktopWeb: 'QIsReY5p8X',
  clientSecretMobileWeb: 'rowoitMV1I',
  get clientSecret() {
    try {
      const isMobile = detectMob();
      const clientSecret = isMobile
        ? this.clientSecretMobileWeb
        : this.clientSecretDesktopWeb;
      return clientSecret;
    } catch (e) {
      return this.clientSecretDesktopWeb;
    }
  },
  defaultCurrency: 'GBP',
  distributedBundle: true,
  bundle: {
    name: 'react,betgoodwin',
    languanges: [{ id: 1, value: 'en' }],
  },
  sportsBundle: {
    name: 'sports',
    languanges: [{ id: 1, value: 'en' }],
  },
  staticPath: {
    // base path
    basePath: 'https://assets.fsbtech.com/',
    // bucket name
    bucketReact: 'react/',
    bucketImages: 'images/',
    bucketOsiros: 'osiros/',
    // folder name
    folderIcon: 'icons/',
    folderMisc: 'misc/',
    folderFlags: 'flags/',
    folderPayments: 'payments/',
    folderVirtuals: 'virtuals/',
  },
  casino: {
    assetsUrl: 'https://assets.fsbtech.com/images/casino',
  },
  timeFormats,
  currency: 'GBP',
  countryDrop: [
    {
      value: 'GB',
      label: 'UNITED KINGDOM',
    },
  ],
  additionalGamblingTabs: ['PRODUCT_RESTRICTION'],
  productRestrictionConfig: [
    {
      productGroupRef: 'SPORTSBOOK',
      urlToMatch: ['/sportsbook/', '/all-in-play/'],
      shouldNotRedirect: true,
    },
    {
      productGroupRef: 'CASINO',
      urlToMatch: '/casino/',
    },
    {
      productGroupRef: 'VIRTUAL',
      urlToMatch: '/virtual/',
    },
  ],
  customerSessionParam: {
    countUnsettledBets: true,
  },
  blacklistMarkets: '',
  appsWithoutCMS: [
    '<fsbapp id="3" data-appid="99" data-app="TCApp" data-title="Important Notice" data-dynamichtml="<div style=padding-bottom:12px ><p>We are informing you that we now offer our customers Casino games and virtual events.</p><br/><p> As such we are required to inform you that we&rsquo;ve made some updates to our website terms and conditions. These can be found here <a style=text-decoration:underline href=/terms-and-conditions/>Terms and Conditions</a> and here <a style=text-decoration:underline href=/virtual-rules/>Virtual Rules </a></p><br/><p>Please note that by continuing to use the Betgoodwin website and services, you are accepting these new terms and conditions. If you have any questions, please don&rsquo;t hesitate to contact our customer service team at <a style=text-decoration:underline href=mailto:support@betgoodwin.com>support@betgoodwin.com</a> or via livechat.</p></div>"></fsbapp>',
  ],
};
