export const SYMBOLS = {
  GBP: '£',
  EUR: '€',
  XBT: 'Ƀ',
  USD: '$',
  KES: 'KSh',
  TZS: 'Tzs',
  GHS: 'GH₵',
  MZN: 'MZN',
  UGX: 'UGX',
  MWK: 'MWK',
  CAD: '$',
  JPY: 'Ұ',
  ZAR: 'R',
};

export const bTag = '?btag';

export function formatNumberWithCurrency(currency, value) {
  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol', //  for short currency symbol .
  }).format(value);
}

export function formatBplanWithCurrency(currency, bplan) {
  for (const product of Object.keys(bplan)) {
    if (!product) {
      return;
    }
    bplan[product].fbalance = formatNumberWithCurrency(
      currency,
      bplan[product].balance
    );
  }
  return bplan;
}

export function processQsMetadata(url) {
  const metadataRegex = /metadata_(.*)=/;
  let queryString = '';
  if (url.includes('?')) {
    queryString = url.split('?')[1];
  }
  const metadataParams = [];
  queryString.split('&').forEach(param => {
    if (metadataRegex.test(param)) {
      const [, key] = param.match(metadataRegex);
      const value = param.split('=')[1];
      metadataParams.push({ [key]: value });
    }
  });
  return metadataParams;
}
