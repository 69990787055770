class Translations {
  constructor() {
    this.t = {};
  }
  get(str) {
    if (!str) return;
    str = str.toLowerCase();
    var v = this.t[str];
    return v ? v.split('&amp;').join('&') : `{${str}}`;
  }
  set(a) {
    this.t[a] = a;
  }
  setAll(store) {
    //FIXME store needs to be consistent accross multiple react apps
    //CHECK SERVER/routes/cms/CmsHandler/StreamToResponse has the same issue
    if (store && store.data && store.data.translations)
      Object.keys(store.data.translations).forEach(k => {
        this.t[k] = store.data.translations[k];
      });

    if (store && store.translations)
      Object.keys(store.translations).forEach(k => {
        this.t[k] = store.translations[k];
      });
  }
}

export class TranslationsProvider {
  translations = {};
  constructor(appName) {
    if (this.translations[appName]) return this.translations[appName];
    this.translations[appName] = new Translations();
    return this.translations[appName];
  }
}

const t = new Translations();
export default t;
