import { useState } from 'react';
import { FSBCustomerSession } from 'Services/core__services';
import usePubSub from './core__usePubSub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';

// provides a more react-like interface to the FSBCustomerSession singleton
// the reference to "sessionData" should update and cause a rerender at the right time
const useFSBSession = () => {
  // initially we get the access token from cookies
  // if not logged in, accessToken will be false
  const [sessionData, setSessionData] = useState(
    FSBCustomerSession.getSession()
  );

  // update session data when the customer logs in
  usePubSub(PubsubEvents.SESSION_CREATE, setSessionData);

  // update session data when pubsub session update event received (after placing a bet or whatever)
  usePubSub(PubsubEvents.SESSION_UPDATED, setSessionData);

  // listening to the pub sub logout is not needed because that process clears the cookie and reloads the page

  return sessionData;
};

export default useFSBSession;
