export const configIcon = {
  // Sportsbook icons
  ALPINE_SKIING: 'icon-alpine-skiing',
  AMERICAN_FOOTBALL: 'icon-american-football',
  ATHLETICS: 'icon-olympics',
  AUSTRALIAN_RULES: 'icon-australian-rules',
  BADMINTON: 'icon-badminton',
  BANDY: 'icon-bandy',
  BASEBALL: 'icon-baseball',
  BASKETBALL: 'icon-basketball',
  BEACH_VOLLEYBALL: 'icon-beach-volleyball',
  BIATHLON: 'icon-biathlon',
  BOXING: 'icon-boxing',
  COLLEGE_BASKETBALL: 'icon-basketball',
  COLLEGE_FOOTBALL: 'icon-american-football',
  CRICKET: 'icon-cricket',
  CROSS_COUNTRY: 'icon-cross-country',
  CYCLING: 'icon-cycling',
  DARTS: 'icon-darts',
  DOGS: 'icon-greyhounds',
  ESPORTS: 'icon-e-sports',
  FLOORBALL: 'icon-floorball',
  FOOTBALL: 'icon-football', // same as soccer icon, but different glyph
  FUTSAL: 'icon-futsal',
  GAELIC_FOOTBALL: 'icon-gaa-football',
  GAELIC_HURLING: 'icon-gaa-hurling',
  GOLF: 'icon-golf',
  HANDBALL: 'icon-handball',
  HORSES: 'icon-horses',
  ICE_HOCKEY: 'icon-ice-hockey',
  MMA: 'icon-martial-arts',
  MOTORSPORT: 'icon-motorsports',
  OLYMPICS: 'icon-olympics',
  POLITICS: 'icon-politics',
  POOL: 'icon-pool',
  PRO_BASEBALL: 'icon-baseball',
  PRO_BASKETBALL: 'icon-basketball',
  PRO_FOOTBALL: 'icon-american-football',
  PRO_HOCKEY: 'icon-ice-hockey',
  RUGBY_LEAGUE: 'icon-rugby-league',
  RUGBY_UNION: 'icon-rugby-union',
  SNOOKER: 'icon-snooker',
  SKI_JUMPING: 'icon-ski-jumping',
  SOCCER: 'icon-soccer',
  SPECIALS: 'icon-specials',
  TABLE_TENNIS: 'icon-table-tennis',
  TENNIS: 'icon-tennis',
  VOLLEYBALL: 'icon-volleyball',
  WATERPOLO: 'icon-waterpolo',

  // Feature icons
  BETGAMES: 'icon-betgames',
  CASINO: 'icon-Casino',
  LOTTO: 'icon-lotto',
  SPORTS: 'icon-Sports', // same as soccer icon, but different glyph
  VIRTUALS: 'icon-virtuals',

  // UI icons
  '18PLUS': 'icon-cc-18',
  '19PLUS': 'icon-19-plus',
  PLAY2: 'icon-in-play2',
  CLOCK: 'icon-clock',
  UP: 'icon-Chevron---Up',
  DOWN: 'icon-Chevron---Down',
  CLOSE: 'icon-Chevron---Left',
  OPEN: 'icon-Chevron---Right',
  BACK: 'icon-Back---Arrow',
  FULLSCREEN: 'icon-screen-full',
  MINSCREEN: 'icon-screen-min',
  TICKMARK: 'icon-Tickmark',
  CLOSE_ICON: 'icon-Close',
  PLUS: 'icon-Plus',
  INFO: 'icon-cc-info',
  INFORMATION: 'icon-Info-in-Circle',
  EXCLAIMATION: 'icon-Exclamation-Mark-in-Circle',
  SLIDER_LEFT: 'icon-slider-arrow-left',
  SLIDER_RIGHT: 'icon-slider-arrow-right',
  BOOSTER: 'icon-price_boost',
  TICKMARK_CIRCLE: 'icon-Tickmark-in-Circle',
  CROSS_CIRCLE: 'icon-Failed---Cross-in-Circle',
  BIN: 'icon-Bin',
  NOBET: 'icon-betslip',
  OPEN_EYE: 'icon-Eye---Open',
  CLOSED_EYE: 'icon-Eye---Closed',
  STAR: 'icon-Favourite-Star',
  STARACTIVE: 'icon-Favourite-Star---Active',
  FILTER: 'icon-Filter',
  FILTER_LIST: 'icon-filter_list',
  STREAMING: 'icon-In-Play',
  CASHOUT: 'icon-cashout',
  USER: 'icon-my-account',
  SPINNER: 'icon-Loading---Spinner',
  LOCK: 'icon-Lock',
  BETS: 'icon-My-Bets',
  SCORES: 'icon-Scores',
  STATS: 'icon-Stats---Statistics',
  MATCH_TRACKER: 'icon-Stream---Match-Tracker',
  PROMOTIONS: 'icon-Promotions',
  SEARCH: 'icon-search',
  LOBBY: 'icon-exit',
  PRICE_BOOST: 'icon-cc-boost',
  PRICE_UNBOOST: 'icon-cc-unboost',
  TVICON: 'icon-live-tv',
  LIVESTREAMING: 'icon-live-streaming',
  CHAT: 'icon-chat',
  SAFEGAMBLING: 'icon-safegambling',
  GIFTBOX: 'icon-giftbox',
  CREATEBET: 'icon-createbet',

  // Logos
  GAMBLE_AWARE: 'icon-cc-BeGambleAware',
  GAMBLING_COMISSION: 'icon-cc-GamblingComission',
  GAMCARE: 'icon-cc-Gamcare',
  GAMSTOP: 'icon-cc-Gamstop',
  IBAS: 'icon-cc-IBAS',
  MASTERCARD: 'icon-cc-mastercard',
  MAESTRO: 'icon-cc-maestro',
  PAYPAL: 'icon-cc-paypal',
  VISA: 'icon-cc-visa',
  VISA_ELET: 'icon-cc-visa-elet',
  SKRILL: 'icon-cc-skrill',
  NETELLER: 'icon-cc-neteller',
  FSB: 'icon-fsb-black-rbg',
};
