import { getRequest } from '../core__services';

const REST_API_URL = '/fsb-api-rest';
const REST_API_EXTENSION = '.fsb';

// Get endpoint to see if recaptcha is required
const getCaptcha = () => {
  const url = `${REST_API_URL}/register/isCaptchaRequired${REST_API_EXTENSION}`;
  return getRequest(url).then(res => res);
};

const attachCaptchaScript = reCaptchaSiteKey => {
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaSiteKey}`;
  script.async = true;
  document.body.appendChild(script);
};

export const loadCaptchaScript = async captchaRequired => {
  if (!captchaRequired || window.grecaptcha) return '';

  const res = await getCaptcha();
  if (res.captcha?.isCaptchaRequired) {
    const reCaptchaSiteKey = res.captcha.reCaptchaSiteKey;
    attachCaptchaScript(reCaptchaSiteKey);
    return reCaptchaSiteKey;
  }
  return '';
};

export const handleCaptchaSubmit = (cb, reCaptchaSiteKey) => {
  window.grecaptcha?.ready(() => {
    window.grecaptcha
      .execute(reCaptchaSiteKey, { action: 'homepage' })
      .then(token => {
        cb(token);
      })
      .catch();
  });
};
