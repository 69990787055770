import {
  formatNumberWithCurrency,
  formatBplanWithCurrency,
} from '../core__session.utils';
import {
  createCookie,
  getCookie,
  removeCookie,
} from '../../cookie/core__cookies';
import { fwvStates } from 'Services/hooks/core__useFWVRedirect';

class FSBCustomerSession {
  constructor() {
    this.accessToken = getCookie('AppSession');
  }

  hasPreviousSession() {
    return this.accessToken ? this.accessToken : false;
  }

  createCookie() {
    createCookie('AppSession', this.customerOBJ.accessToken);
    createCookie('userName', this.customerOBJ.userName);
    createCookie('custId', this.customerOBJ.customerId);
  }
  createCookieFromAuthenticate(customer) {
    createCookie('AppSession', customer.accessToken);
    createCookie('userName', customer.userName);
    createCookie('custId', customer.id);
  }
  getSession() {
    if (!this.customerOBJ) return false;

    return this.customerOBJ;
  }

  updateSession(cApi) {
    this.customerOBJ = {
      raw: cApi,
      accessToken: cApi.accessToken,
      id: cApi.id,
      userName: cApi.userName,
      firstName: cApi.firstName,
      lastName: cApi.lastName,
      fullName: `${cApi.firstName} ${cApi.lastName}`,
      dateOfBirth: new Date(cApi.dob),
      title: cApi.title,
      titleFullName: `${cApi.title} ${cApi.firstName} ${cApi.lastName}`,
      tsAndCs: cApi.tsAndCs,
      marketingOk: cApi.marketingOk,
      kycApproved: cApi.kycApproved,
      currencyCode: cApi.currencyCode,
      customerId: cApi.id,
      localeKey: cApi.localeKey,
      mobileNumber: cApi.mobile,
      email: cApi.email,
      registered: new Date(cApi.registered),
      platformConfig: cApi.platformConfig ? cApi.platformConfig : false,
      balance: {
        balance: cApi.balance,
        casinoBalance: cApi.altBalance,
        sportsbookBalance: cApi.sportBonusBalance,
        fbalance: formatNumberWithCurrency(cApi.currencyCode, cApi.balance),
        fcasinoBalance: formatNumberWithCurrency(
          cApi.currencyCode,
          cApi.altBalance
        ),
        fsportsbookBalance: formatNumberWithCurrency(
          cApi.currencyCode,
          cApi.sportBonusBalance
        ),
        withdrawableBalance: cApi.withdrawableBalance,
        totalBalance: cApi.balance + (cApi.withdrawableBalance || 0),
      },
      bplanBalance: formatBplanWithCurrency(
        cApi.currencyCode,
        cApi.bplanBalance
      ),
      wallet: typeof cApi.wallet != 'undefined' ? cApi.wallet : [],
      unsettledBetsCount: cApi.unsettledBetsCount || 0,
      segmentRef: cApi.segmentRef || '',
      firstWithdrawalVerified: cApi.firstWithdrawalVerified || fwvStates.FALSE, // default is false
      rgLimit: cApi.rgLimitCustomer,
    };

    return this.customerOBJ;
  }

  deleteSession() {
    removeCookie('AppSession');
    removeCookie('userName');
    removeCookie('custId');
    this.accessToken = false;
    this.customerOBJ = {};
  }
}

const singleton = new FSBCustomerSession();
export default singleton;
