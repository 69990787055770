import { getCookie } from '../cookie/core__cookies';

/**
 * Method to get odds Format
 */
const OddsFormat = () => {
  const getOddsFormat = getCookie('selectedOdds');
  return getOddsFormat;
};

/**
 *
 * @param {String} word
 * @returns string
 */
const capitalizedCase = word => {
  const result = word
    .split('_')
    .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(' ');
  return result;
};

/**
 * convert first letter in caps
 * @param {String} string
 * @returns string
 */
const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

/**
 *
 * @param {String} eventTitles
 * @param {String} eventLinks
 * @returns Array of objects
 */
const generateNoEventsDataArray = (eventTitles, eventLinks) => {
  const links = eventLinks?.split(',')?.map(item => {
    return item.trim();
  });
  const names = eventTitles?.split(',')?.map(item => {
    return item.trim();
  });
  const noEventData = [];
  for (let i = 0; i < names?.length; i++) {
    const name = names[i];
    const link = links[i] || '';
    noEventData.push({ name, link });
  }
  return noEventData;
};

/**
 *
 * @param {Number} len length of random string to be generated
 * @returns random string of lenghtn len
 */
const getRandomString = len => {
  return Math.random()
    .toString(36)
    .slice(2, len + 2);
};

const getHandicapFromStr = str => {
  const regex = /[-+]\d+[.]?\d?/gm;

  let m;
  let v;
  while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach(match => {
      v = match;
    });
  }

  return v;
};

const sortObjectArrayByKey = (arr, key, integer = true, market_ref) => {
  arr.sort(function (a, b) {
    var keyA = integer ? parseInt(a[key]) : a[key],
      keyB = integer ? parseInt(b[key]) : b[key];
    if (market_ref === 'SHC_LS') {
      keyA = parseInt((keyA + '').substring(1));
      keyB = parseInt((keyB + '').substring(1));
    }
    // Compare the 2 dates
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });
  return arr;
};

const replaceSpecialCharacter = str => {
  return str.split('&amp;').join('&');
};

const detectMob = (getOS = false) => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  if (getOS) {
    let matched = null;
    toMatch.forEach(toMatchItem => {
      if (navigator?.userAgent?.match(toMatchItem)) {
        matched = toMatchItem;
      }
    });
    return matched;
  }
  return toMatch.some(toMatchItem => {
    return navigator?.userAgent?.match(toMatchItem);
  });
};

/**
 *
 * @param {String} translatedText
 * @param {Object.<string, string | number>} parameter
 * @return {string}
 */
const getInterpolatedParametersInTranslation = (translatedText, parameter) => {
  const keys = Object.keys(parameter || {});

  if (keys.length) {
    let resultText = translatedText;
    keys.forEach(key => {
      resultText = resultText.replace(`{${key}}`, parameter[`${key}`]);
    });
    return resultText;
  } else {
    return translatedText;
  }
};

/**
 *
 * @param {Array} arr
 * @param {String} key
 * @return {Array}
 */

const sortByProperty = (arr, key) => {
  arr?.sort(function (x, y) {
    return x[key] - y[key];
  });
  return [...arr];
};

/**
 * This function is used to sort the array of json based on nested key.
 * @param {String} nestedProperty
 */

function sortByNestedProperty(prop, arr) {
  prop = prop.split('.');
  var len = prop.length;

  arr.sort(function (elm1, elm2) {
    var i = 0;
    let a = elm1;
    let b = elm2;
    while (i < len) {
      a = a[prop[i]];
      b = b[prop[i]];
      i++;
    }
    if (a > b) {
      return -1;
    } else if (a < b) {
      return 1;
    }
  });
  return arr;
}

const isAndroidApp = () =>
  navigator?.userAgent?.includes('app=fsb') || getCookie('isAndroidApp');

const stringToArray = str => (typeof str === 'string' ? str.split(',') : str);

const getLastRoute = url => {
  const pathname = new URL(url).pathname;
  const parts = pathname.split('/').filter(Boolean);
  return parts[parts.length - 1];
};

// detect Mobile device
const isMobileDevice = () => {
  try {
    return window.outerWidth <= 600;
  } catch (error) {
    return null;
  }
};

export {
  OddsFormat,
  capitalizedCase,
  capitalizeFirstLetter,
  generateNoEventsDataArray,
  getRandomString,
  getHandicapFromStr,
  sortObjectArrayByKey,
  replaceSpecialCharacter,
  detectMob,
  getInterpolatedParametersInTranslation,
  sortByProperty,
  sortByNestedProperty,
  isAndroidApp,
  stringToArray,
  getLastRoute,
  isMobileDevice,
};
