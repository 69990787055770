/* 
for call this function need to import the file 
params :
it accept data in object format
{
    keyName: (name of localstorage key)
    action: ('set'/ 'get')
    keyValue:(in array format for set is require)
}

example
 import {setterGetterFromLocalstorage} from "../../../../services/localstorage/core__localstorage"

for seting data
 setterGetterFromLocalstorage({
        keyName: 'betslip_uk',
        action: 'set',
        keyValue: [],
      });

for geting data
const localData = setterGetterFromLocalstorage({
      keyName: 'betslip_uk',
      action: 'get',
    });
*/

export const setterGetterFromLocalstorage = data => {
  const setStorage = () => {
    try {
      localStorage.setItem(data['keyName'], JSON.stringify(data['keyValue']));
      return true;
    } catch (error) {
      return error.message;
    }
  };
  const getStorage = () => {
    try {
      const l_data = JSON.parse(localStorage.getItem(data['keyName']));
      return l_data ? l_data : [];
    } catch (error) {
      return [];
    }
  };
  if (data.action === 'set') {
    return setStorage();
  } else if (data.action === 'get') {
    return getStorage();
  }
};

/* 
this function for setting and geting data form Localstorage 

params Object
*/

export const setUserBetInlocalStorage = (obj, LocalStorageKey) => {
  const localData = setterGetterFromLocalstorage({
    keyName: LocalStorageKey,
    action: 'get',
  });
  const bbdata = [];
  for (let i = 0; i < localData.length; i++) {
    const lData = localData[i];

    if (lData['id'] === obj['id']) {
      lData['isValid'] = obj.isValid;
      lData['userStake'] = (obj.userStake && obj.userStake) || '';
      lData['totalStake'] = (obj.totalStake && obj.totalStake) || '';
      lData['_returns'] = (obj._returns && obj._returns) || '';
      lData['returnsText'] = (obj.returnsText && obj.returnsText) || '';
      lData['_returnsFormatted'] =
        (obj._returnsFormatted && obj._returnsFormatted) || '';
      lData['lastModified'] =
        (obj.lastModified && obj.lastModified) ||
        JSON.parse(JSON.stringify(new Date()));
      lData['priceDecimal'] = (obj.priceDecimal && obj.priceDecimal) || '';
      lData['priceFractional'] =
        (obj.priceFractional && obj.priceFractional) || '';
      lData['bbFlag'] = (obj.bbFlag && obj.bbFlag) || false;
      lData['hasFreebet'] = (obj.hasFreebet && obj.hasFreebet) || false;
      lData['selectedFreebetId'] =
        (obj.selectedFreebetId && obj.selectedFreebetId) || '';
      lData['freebetCredit'] = (obj.freebetCredit && obj.freebetCredit) || '';
      lData['freebetCreditFormatted'] =
        (obj.freebetCreditFormatted && obj.freebetCreditFormatted) || '';
      lData['freebetRestrictions'] =
        (obj.freebetRestrictions && obj.freebetRestrictions) || '';
      lData['betModifierBetCount'] = obj.betModifierBetCount
        ? obj.betModifierBetCount
        : // eslint-disable-next-line ternaries/no-empty-ternary
        obj.betModifier?.length > 0
        ? obj.betModifier[0].betCount
        : '';
      lData['betModifierType'] =
        (obj.betModifierType && obj.betModifierType) || '';
      lData['betModifierFlag'] =
        (obj.betModifierFlag && obj.betModifierFlag) || '';
      lData['castPosition'] = (obj.castPosition && obj.castPosition) || '';
      lData['priceBookId'] = (obj.priceBookId && obj.priceBookId) || '';
      lData['priceBookType'] = (obj.priceBookType && obj.priceBookType) || '';
      lData['priceCurrent'] = (obj.priceCurrent && obj.priceCurrent) || '';
      lData['priceId'] = (obj.priceId && obj.priceId) || '';
      lData['betModifierFractional'] =
        (obj.betModifierFractional && obj.betModifierFractional) || '';
      lData['betModifierReturns'] =
        (obj.betModifierReturns && obj.betModifierReturns) || '';
      lData['spFlag'] = (obj.spFlag && obj.spFlag) || false;
      lData['bonusFunds'] = (obj.bonusFunds && obj.bonusFunds) || '';
      lData['updateNewPrice'] =
        (obj.updateNewPrice && obj.updateNewPrice) || '';
      lData['priceChangeNotification'] =
        (obj.priceChangeNotification && obj.priceChangeNotification) || '';
      lData['previousPriceDecimal'] =
        (obj.previousPriceDecimal && obj.previousPriceDecimal) || '';
      lData['previousPriceFractional'] =
        (obj.previousPriceFractional && obj.previousPriceFractional) || '';
      lData['disappear'] = obj.disappear || false;
    }

    if (lData['betTypeRef'] === 'BBB') {
      const d = {};

      d.id = lData['id'];
      d.userStake = lData['userStake'];
      d.totalStake = lData['totalStake'];
      if (lData['hasFreebet']) {
        d.freebetCredit = lData['freebetCredit'];
        d.freebetCreditFormatted = lData['freebetCreditFormatted'];
        d.hasFreebet = lData['hasFreebet'];
        d.selectedFreebetId = lData['selectedFreebetId'];
      }

      bbdata.push(d);
    }
  }
  if (bbdata.length > 0) {
    //  if builder data come
    setterGetterFromLocalstorage({
      keyName: 'buideruseData',
      action: 'set',
      keyValue: bbdata,
    });
  }

  setterGetterFromLocalstorage({
    keyName: LocalStorageKey,
    action: 'set',
    keyValue: localData,
  });

  return setterGetterFromLocalstorage({
    keyName: LocalStorageKey,
    action: 'get',
  });
};

export const removeFromLocalStorage = ({ selectionId }, LocalStorageKey) => {
  const localData = setterGetterFromLocalstorage({
    keyName: LocalStorageKey,
    action: 'get',
  });
  localData.map((bets, key) => {
    if (selectionId && bets['selectionId'] === selectionId) {
      localData.splice(key, 1);
    }
  });

  setterGetterFromLocalstorage({
    keyName: LocalStorageKey,
    action: 'set',
    keyValue: localData,
  });
  return localData;
};

export const removeFromLocalStorageDeleteSuspended = (
  type,
  LocalStorageKey
) => {
  const localData = setterGetterFromLocalstorage({
    keyName: LocalStorageKey,
    action: 'get',
  });
  if (type && type === 'deletesuspended') {
    let ar = localData.filter(item => item.active);
    setterGetterFromLocalstorage({
      keyName: LocalStorageKey,
      action: 'set',
      keyValue: ar,
    });
    // when all single bet expire for that need to remove multiple bets also
    if (Array.isArray(ar) && ar.filter(item => !item.multiple).length === 0) {
      ar = ar.filter(item => !item.multiple);
    }

    return ar;
  }
};
