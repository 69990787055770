/**
 *
 * @param {String} word
 * @returns string
 */
const capitalizedCase = word => {
  const result = word
    .replace(/\s+/g, ' ')
    .trim()
    .split(/[ _]+/)
    .map(w => {
      if (w[0] === '(') return w;
      return w[0].toUpperCase() + w.substr(1).toLowerCase();
    })
    .join(' ');
  return result;
};

export { capitalizedCase };
