/**
 * Creates URL from URLServiceData object in the format of the URLServiceConfig.
 * Prefix definitions:
 * $ = All lowercase with dash to separate.
 * # = Upper case on first char and dash replacing all non alpha numeric chars.
 * : = Leave as is.
 */
export class UrlService {
  constructor(config = {}) {
    this.pattern = config.pattern;
    this.apiDataMap = new Map(); // needed for parsing url
  }

  isParam(str) {
    return /^[$|#|:|_]+/g.test(str);
  }

  isNumericParam(str) {
    return str.toLowerCase().indexOf('id') > -1;
  }

  slugify(str, underscored = false) {
    if (underscored)
      return str
        .split(/[^a-zA-Z0-9]/g)
        .filter(Boolean)
        .join('_');
    return str
      .split(/[^a-zA-Z0-9]/g)
      .filter(Boolean)
      .join('-');
  }

  _processPatternPart(part, data = {}) {
    if (part === '') return part;
    if (!this.isParam(part)) return part;

    // combined part b.e. $subcategoryName-$subcategoryId
    if (part.indexOf('-') > -1) {
      const combinedParts = part.split('-');
      const processedCombinedParts = combinedParts.map(x =>
        this._processPatternPart(x, data)
      );
      return processedCombinedParts.filter(Boolean).join('-');
    }
    // Resolve data key; ignore part if not exists
    const key = String(part).slice(1);
    if (!Object.keys(data).includes(key)) return false;
    // Special case; categoryRef MUST be all uppercase
    if (part.slice(0, 1) === '$')
      return this.slugify(String(data[key]).toLowerCase());
    if (part.slice(0, 1) === '#') return this.slugify(String(data[key]));
    if (part.slice(0, 1) === '_') return this.slugify(String(data[key]), true);
    if (part.slice(0, 1) === ':') return data[key];

    return false;
  }

  _processQueryString(queryStr, data = {}) {
    if (!queryStr) return false;
    let result = queryStr;
    const matches = queryStr.match(/[$|:|#|_][a-zA-Z0-9]+/g);
    matches.forEach(match => {
      const val = this._processPatternPart(match, data);
      if (val) result = result.replace(match, val);
    });
    return result;
  }

  /**
   * Probably can be simpler
   * The purpose of this method is to parse data from "combined" url parts
   * "combined" url part is the part with "-" between parameters
   * The issue here is that we are "slugifying" url and "-" symbol is the part of the process
   * this method need to recognize which "-" is a part of slugified string OR delimiter
   * Current solution asumes there are no more than two parameters
   * b.e.
   *  1. english-premier-league-12345 => $subcategoryName-$subcategoryId
   *  2. 12345-english-premier-league => $subcategoryId-$subcategoryName
   *
   * @param {String} pattern
   * @param {String} urlPart
   */
  _parseCombinedPart(pattern, urlPart) {
    pattern.split('-').forEach((part, index) => {
      if (!this.isParam(part)) return;
      const valueParts = urlPart.split('-');
      if (index === 0 && this.isNumericParam(part)) {
        this.apiDataMap.set(part.slice(1), valueParts.shift());
      } else if (index === 0) {
        this.apiDataMap.set(
          part.slice(1),
          valueParts.slice(0, valueParts.length - 1).join('-')
        );
      }
      if (index > 0 && this.isNumericParam(part)) {
        this.apiDataMap.set(part.slice(1), valueParts.pop());
      } else if (index > 0) {
        this.apiDataMap.set(part.slice(1), valueParts.slice(1).join('-'));
      }
    });
  }

  _parseUrl(url) {
    this.apiDataMap = new Map();
    // parse url into data object
    const patternParts = this.pattern.split('/');
    const urlParts = url.split('/');
    patternParts.forEach((part, index) => {
      if (!this.isParam(part)) return;
      if (!urlParts[index]) return;
      if (part.indexOf('-') > -1)
        return this._parseCombinedPart(part, urlParts[index]);
      this.apiDataMap.set(part.slice(1), urlParts[index]);
    });
    return this.apiDataMap;
  }

  /**
   * Stringify url object to string
   * @param {Object} data
   */
  stringify(data = {}) {
    const [uri, queryStr] = this.pattern.split('?');
    const processedParts = uri
      .split('/')
      .map(part => this._processPatternPart(part, data));
    const url = processedParts.filter(Boolean).join('/');
    const outputUrl = ['/', url.replace(/^\//i, '')].join('');
    const outputQuery = this._processQueryString(queryStr, data);
    if (outputQuery) return [outputUrl, outputQuery].join('?');
    if (!outputQuery) {
      return outputUrl.replace(/\/?$/, '/');
    }
  }

  /**
   * Parse url string into object
   * @param {String} url
   */
  parse(url) {
    const dataMap = this._parseUrl(url);
    const data = {};
    dataMap.forEach((value, key) => {
      if (key === 'categoryRef') data[key] = String(value).toUpperCase();
      else data[key] = value;
    });
    return data;
  }
}
