import axios from 'axios';
import { decode } from '@msgpack/msgpack';

import decoder from '../json/core__decoder';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import project from '../../project';
import { getCookie } from '../cookie/core__cookies';
export function getRequest(url, data = {}) {
  if (url.indexOf('.fsb') === -1) {
    return getRequestNoTransform(url);
  }
  return axios
    .get(url, {
      params: data,
      dataType: 'msgpack',
      responseType: 'arraybuffer',
      transformResponse: res => new decoder(decode(res)),
    })
    .then(res => res.data.response)
    .catch(err => {
      return {
        response: err?.response?.data?.response,
        statusCode: err?.response?.status,
        statusText: err?.response?.statusText,
        inError: true,
      };
    });
}

export function getRequestNoTransform(url, callBack) {
  const ax = axios.get(url);
  if (callBack) {
    ax.then(callBack);
  }

  ax.catch(err => {
    return {
      response: err.response.data.response,
      statusCode: err.response.status,
      statusText: err.response.statusText,
      inError: true,
    };
  });
  return ax;
}

export function putRequest(url, data = {}, options = {}) {
  let dataObject = data;
  if (!options.rawObject) {
    dataObject = Object.keys(data)
      .map(key => `${key}=${data[key]}`)
      .join('&');
  }
  const { headers } = options;
  return axios
    .put(url, dataObject, {
      headers,
      dataType: 'msgpack',
      responseType: 'arraybuffer',
      transformResponse: res => new decoder(decode(res)),
    })
    .then(res => res.data.response)
    .catch(err => {
      return {
        response: err.response.data.response,
        statusCode: err.response.status,
        statusText: err.response.statusText,
      };
    });
}

export function postRequest(url, data = {}, options) {
  let dataObject =
    (data &&
      Object.keys(data)
        .map(key => `${key}=${data[key]}`)
        .join('&')) ||
    '';

  let headers = {};
  if (options?.headers) {
    headers = options.headers;
  }
  if (options?.encode) {
    const urlParams = new URLSearchParams(data);
    dataObject = urlParams.toString();
  }
  return axios
    .post(url, dataObject, {
      dataType: 'msgpack',
      responseType: 'arraybuffer',
      transformResponse: res => new decoder(decode(res)),
      headers,
    })
    .then(res => {
      if (options?.customReturn) return options?.customReturn(res);
      return res.data.response;
    })
    .catch(err => {
      return {
        response: err?.response?.data.response,
        statusCode: err?.response?.status,
        statusText: err?.response?.statusText,
        inError: true,
      };
    });
}

export function postRequestOsiros(url, data = {}) {
  const dataObject = Object.keys(data)
    .map(key => `${key}=${data[key]}`)
    .join('&');
  return axios
    .post(url, dataObject)
    .then(res => res.data.response)
    .catch(err => {
      return {
        response: err?.response?.data?.response,
        statusCode: err?.response?.status,
        statusText: err?.response?.statusText,
        inError: true,
      };
    });
}

export function deleteRequest(url, data = {}) {
  const dataObject = Object.keys(data)
    .map(key => `${key}=${data[key]}`)
    .join('&');
  return axios
    .delete(url, dataObject, {
      dataType: 'msgpack',
      responseType: 'arraybuffer',
      transformResponse: res => new decoder(decode(res)),
    })
    .then(res => res.data.response)
    .catch(err => {
      return {
        response: err.response.data.response,
        statusCode: err.response.status,
        statusText: err.response.statusText,
        inError: true,
      };
    });
}

export function postRequestHeader(url, data = {}) {
  return axios
    .post(url, data, {
      'Content-Type': 'multipart/form-data',
      dataType: 'msgpack',
      responseType: 'arraybuffer',
      transformResponse: res => new decoder(decode(res)),
    })
    .then(res => {
      return res.data.response;
    })
    .catch(err => {
      return {
        response: err.response.data.response,
        statusCode: err.response.status,
        statusText: err.response.statusText,
        inError: true,
      };
    });
}

export function postRequestUrl(url, data = {}, headers) {
  return axios
    .post(url, data, {
      headers: {
        'Content-Type': headers['Content-Type'],
      },
      dataType: 'msgpack',
      responseType: 'arraybuffer',
      transformResponse: res => new decoder(decode(res)),
    })
    .then(res => {
      return res.data.response;
    })
    .catch(err => {
      return {
        response: err.response.data.response,
        statusCode: err.response.status,
        statusText: err.response.statusText,
        inError: true,
      };
    });
}

export const postFormData = (url, data) => {
  var formdata = new FormData();
  Object.keys(data).forEach(key => {
    formdata.append(key, data[key]);
  });

  var config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formdata,
    dataType: 'msgpack',
    responseType: 'arraybuffer',
    transformResponse: res => new decoder(decode(res)),
  };

  return axios(config)
    .then(res => {
      return res.data.response;
    })
    .catch(err => {
      return {
        response: err.response.data.response,
        statusCode: err.response.status,
        statusText: err.response.statusText,
        inError: true,
      };
    });
};
export function postRequestJson(url, data = {}, headers) {
  return axios
    .post(url, data, {
      headers: {
        'Content-Type': headers['Content-Type'],
      },
    })
    .then(res => {
      return res.data.response;
    })
    .catch(err => {
      return {
        response: err.response.data.response,
        statusCode: err.response.status,
        statusText: err.response.data.message,
        inError: true,
      };
    });
}

//interceptors
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    //do pubsub emit
    if (
      error?.response?.status === 401 &&
      error?.response?.data?.response?.status?.devMessage !==
        'invalid.old.password'
    ) {
      PubSub.emit(PubsubEvents.SESSION_EXPIRED, error?.response?.status);
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
if (project.isGeoCompliant) {
  axios.interceptors.request.use(
    request => {
      const geo = getCookie('geo');

      if (
        request.method === 'post' &&
        request.url.indexOf('/fsb-api-rest/bet.') !== -1 &&
        !geo
      ) {
        //emit pubsub popup
        PubSub.emit(PubsubEvents.GEOCOMPLY_EVENT, { showGeocomplyCheck: true });
        throw new axios.Cancel();
      } else {
        return request;
      }
    },
    error => {
      return Promise.reject(error);
    }
  );
}
