export const checkEventsHeaderAppActive = appDomSelector => {
  const eventHeaderApp = appDomSelector?.parentElement?.querySelector(
    `[data-app=${'EventHeaderApp'}]`
  );
  if (eventHeaderApp) {
    const eventHeaderAppIndex = [].indexOf.call(
      appDomSelector.parentElement.children,
      eventHeaderApp
    );
    const appIndex = [].indexOf.call(
      appDomSelector.parentElement.children,
      appDomSelector
    );

    if (eventHeaderAppIndex !== -1 && eventHeaderAppIndex < appIndex) {
      return true;
    }
  } else {
    return false;
  }
};
