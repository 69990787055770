/**
 * @param {string} eventTitles
 * @param {string} eventLinks
 * @returns array of objects
 */
export const generateNoEventsDataArray = (eventTitles, eventLinks) => {
  const links = eventLinks?.split(',')?.map(item => {
    return item.trim();
  });
  const names = eventTitles?.split(',')?.map(item => {
    return item.trim();
  });
  const noEventData = [];
  for (let i = 0; i < names?.length; i++) {
    const name = names[i];
    const link = links[i] || '';
    noEventData.push({ name, link });
  }
  return noEventData;
};
