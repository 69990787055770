import moment from 'moment';
import 'moment-timezone';
import Translations from '../translations/core__translations';

const getToFromTodayWithOffset = offSet => {
  const offsetNumber = parseFloat(offSet);
  const isValidNumber = !isNaN(offSet);
  const time =
    moment
      .tz('Europe/London')
      .add(offsetNumber, 'hours')
      .format('YYYYMMDDTHHmmss') + 'Z';
  if (isValidNumber) {
    return time;
  } else return '';
};

const getHoursFromMinutes = minutes => {
  let returnTime = minutes + 'minutes';

  if (minutes > 60) {
    returnTime = Math.floor(parseInt(minutes) / 60);
    returnTime++;
    returnTime = returnTime + ' hours';
  }

  return returnTime;
};

const getToAndFromWithOffset = (isUTC = false, dayOffset) => {
  const dates = [];
  dayOffset = dayOffset ? dayOffset : 0;
  if (isUTC) {
    dates.push(
      moment
        .utc(new Date())
        .add(dayOffset, 'days')
        .startOf('day')
        .format('YYYYMMDDTHHmmss') + 'Z'
    );
    dates.push(
      moment
        .utc(new Date())
        .add(dayOffset, 'days')
        .endOf('day')
        .format('YYYYMMDDTHHmmss') + 'Z'
    );
  } else {
    dates.push(
      new moment()
        .add(dayOffset, 'days')
        .startOf('day')
        .format('YYYYMMDDTHHmmss') + 'Z'
    );
    dates.push(
      new moment()
        .add(dayOffset, 'days')
        .endOf('day')
        .format('YYYYMMDDTHHmmss') + 'Z'
    );
  }

  return dates;
};

const getDateObject = timestamp => {
  const date = moment(timestamp).format('Do MMM h mm a').split(' ');
  return {
    day: date[0],
    month: date[1],
    hours: date[2],
    minutes: date[3],
    period: date[4],
  };
};

//FIXME
const dateOrdinal = d =>
  d +
  (31 === d || 21 === d || 1 === d
    ? Translations.get('number.st')
    : 22 === d || 2 === d
    ? Translations.get('number.nd')
    : 23 === d || 3 === d
    ? Translations.get('number.rd')
    : Translations.get('number.th'));

const getDate = ({ scheduledStart }) => {
  const date = moment(scheduledStart);
  const day = dateOrdinal(date.date());
  const hour = date.format('h');
  const minutes = date.format('mm');
  const month = Translations.get(`month.${date.format('MMM')}`);

  const time =
    `${hour}:${minutes} ` +
    (date.hour() >= 12
      ? Translations.get('time.pm')
      : Translations.get('time.am'));

  return `${day} ${month}, ${time}`;
};

const getDateFormat = scheduledStart => {
  const dateTime = moment(scheduledStart);
  const date = dateTime.format('DD');
  const hour = dateTime.hour();
  const minutes = dateTime.minutes().toString().padStart(2, '0');
  const month = dateTime.format('MM');
  const year = dateTime.format('YYYY');
  return `${date}/${month}/${year} ${hour}:${minutes}`;
};

// finding diffrence between 2 dates .
const getDaysDifference = (count, format) => {
  //format should be goes in {d,M}
  const currentData = moment();
  const nextDate = moment().add(count, format);
  return nextDate.diff(currentData, 'days');
};

const padTo2Digits = num => {
  return num.toString().padStart(2, '0');
};

const convertDate = str => {
  const darr = str.split('-');
  const dobj = new Date(
    parseInt(darr[2]),
    parseInt(darr[1]) - 1,
    parseInt(darr[0])
  );
  return dobj;
};

const convertToISO = value => {
  return new Date(convertDate(value.split('-').reverse().join('-')))
    .toJSON()
    .replace(/[^a-zA-Z0-9 ]/g, '');
};

const formatDate = date => {
  if (date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-');
  }
};
const getMonthNumberFromName = monthName => {
  const months = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12',
  };
  return months[monthName];
};

const checkDate = (day, month, year) => {
  let result = true;
  if (day && month && year) {
    if (typeof month === 'string') {
      month = getMonthNumberFromName(month);
    }
    const dateformat = /^\d\d\d\d-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])$/;
    const date = `${year}-${month}-${day}`;
    // Matching the date through regular expression
    if (date.match(dateformat)) {
      const operator = date.split('-');

      // Extract the string into month, date and year
      let datepart = [];
      if (operator.length > 1) {
        datepart = date.split('-');
      }
      const year = parseInt(datepart[0]);
      const month = parseInt(datepart[1]);
      const day = parseInt(datepart[2]);
      // Create a list of days of a month
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          //to check if the date is out of range
          result = false;
        }
      } else if (month === 2) {
        let leapYear = false;
        if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
        if (leapYear && day >= 29) result = false;
        else if (leapYear && day > 29) {
          result = false;
        }
      }
    } else {
      result = false;
    }
  }
  return result;
};

export {
  getToFromTodayWithOffset,
  getHoursFromMinutes,
  getToAndFromWithOffset,
  getDateObject,
  getDate,
  getDateFormat,
  getDaysDifference,
  convertToISO,
  formatDate,
  checkDate,
};
