import Cookies from 'js-cookie';
import project from '../../project';

export const createCookie = (name, value, expires, settings = {}) => {
  const cookieSettings = settings || {};

  if (project?.cookieSettings) {
    Object.assign(cookieSettings, project.cookieSettings);
  }

  if (Cookies.get(name, cookieSettings)) Cookies.remove(name, cookieSettings);

  if (!expires) expires = 90;

  Cookies.set(name, value, { ...cookieSettings, expires });
};

export const removeCookie = name => {
  let cookieSettings = {};
  if (project?.cookieSettings) {
    cookieSettings = project.cookieSettings;
  }
  Cookies.remove(name, cookieSettings);
};

export const getCookie = name => {
  let cookieSettings = {};
  if (project?.cookieSettings) {
    cookieSettings = project.cookieSettings;
  }
  const cookie = Cookies.get(name, cookieSettings);

  return cookie !== '' && cookie !== undefined ? cookie : false;
};

export const getAllCookie = () => {
  return Cookies.get() || null;
};
