import project from '../../project';
import { UrlServiceFactory } from './service/core__url-service-factory';

/** Resolve configuration from project.js */
const projectConfig = project?.urlServiceConfig || {};

const sportsbookConfig = {
  pattern:
    projectConfig?.sportsbook?.pattern ||
    '/$localizedPath/sportsbook/$categoryRef/$subcategoryName-$subcategoryId/$eventName/:eventId',
  apiPattern:
    projectConfig?.sportsbook?.apiPattern ||
    '/bet/category/name/#categoryRef/id/$subcategoryId',
};
const virtualConfig = {
  pattern:
    projectConfig?.virtual?.pattern ||
    '/$localizedPath/virtual/$categoryRef/$eventName/',
  apiPattern:
    projectConfig?.virtual?.apiPattern ||
    '/bet/category/name/#categoryRef/id/$subcategoryId',
};
const lottoConfig = {
  pattern:
    projectConfig?.lotto?.pattern || '/$localizedPath/lotto/$categoryRef/',
  apiPattern:
    projectConfig?.lotto?.apiPattern ||
    '/bet/category/name/#categoryRef/id/$subcategoryId',
};
const casinoConfig = {
  pattern:
    projectConfig?.casino?.pattern || '/casino/$productRef/#gameName-:gameId',
  apiPattern:
    projectConfig?.casino?.apiPattern ||
    '/casino/$productRef/_gameName?id=:gameId',
};

/** UrlAmendment services */
export const SportsbookUrlService = new UrlServiceFactory(sportsbookConfig);
export const CasinoUrlService = new UrlServiceFactory(casinoConfig);
export const VirtualUrlService = new UrlServiceFactory(virtualConfig);
export const LottoUrlService = new UrlServiceFactory(lottoConfig);
