import { UrlService } from './core__url-service';

export class UrlServiceFactory {
  constructor(config = {}) {
    this.browserService = new UrlService({ pattern: config.pattern });
    this.apiService = new UrlService({ pattern: config.apiPattern });
  }

  /**
   * Create url for the browser
   * @param {Object} data
   * @returns
   */
  createUrlForTheBrowser(data = {}) {
    return this.browserService.stringify(data);
  }

  /**
   * Create url for the api
   * @param {String} url
   * @returns
   */
  createUrlForTheApi(url) {
    const data = this.browserService.parse(url);
    return this.apiService.stringify(data);
  }
}
