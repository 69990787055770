// https://momentjs.com/docs/#/displaying/format/
export const timeFormats = {
  accounts: {
    primary: 'DD/MM/YYYY h:mm',
    secondary: 'DD/MM/YYYY',
    tertiary: 'DD/MM/YYYY, HH:mm',
    quaternary: 'DD/MM/YYYY h:mm a',
  },
  betslip: {
    primary: 'DD/MM/YYYY, hh:mm',
    secondary: 'DD/MM/YYYY, HH:mm',
  },
  events: {
    primary: 'YYYY-MM-DD',
    secondary: 'dddd Do MMMM',
    tertiary: 'x',
  },
  bets: {
    primary: 'YYYY/MM/DD HH:mm:ss',
  },
  search: {
    primary: 'Do MMM, h:mm a',
    secondary: 'D MMM',
    tertiary: 'HH:mm',
    quaternary: 'D MMM, h:mm a',
  },
  promotion: {
    primary: 'DD:MM',
    secondary: 'ddd, DD MMM hh:mm',
    tertiary: 'D MMM HH:mm',
  },
  racing: {
    primary: 'YYYYMMDDTHHmmss',
    secondary: 'dddd, D MMMM YYYY',
    tertiary: 'DD/MM/YYYY HH:mm',
    quaternary: 'HH:mm',
  },
  upcomingFavs: {
    primary: 'YYYYMMDDTHHmmss',
  },
  virtual: {
    primary: 'DD MMM YY',
  },
  scoreboard: {
    primary: 'Do MMM YYYY, h:mm a',
    secondary: 'D MMM, hh:mm',
  },
  registration: {
    primary: 'DD/MMM/YYYY',
    secondary: 'YYYY-MM-DD',
    tertiary: 'YYYY-MMM-DD',
  },
  logintimer: {
    primary: 'DD/MM/YYYY h:mm a',
    secondary: 'DD/MM/YYYY HH:mm:ss',
    tertiary: 'HH:mm:ss',
    quaternary: 'mm:ss',
    penitentiary: 'DD/MM/YYYY h:mm',
  },
  casino: {
    primary: 'DD-MM-YYYY',
  },
  lotto: {
    primary: 'ddd D MMMM',
    secondary: 'HH:mm',
    tertiary: 'ddd, D MMMM HH:mm',
    history: 'DD/MM/YYYY HH:mm',
    lottoHistory: 'ddd D MMMM HH:mm',
  },
  history: {
    primary: 'MM/DD/YYYY HH:MM:SS',
  },
};
