import Translations from '../translations/core__translations';
import { Constants } from '../../sportsbook/components/EventsApp/core__events-constants';
import moment from 'moment';
/**
 * Method to get american odds
 */
const getOdds = (odds, isAmerican) => {
  if (isAmerican) {
    const over = odds => (odds - 1) * 100;
    const under = odds => -100 / (odds - 1);
    const americanOdds =
      Math.trunc(odds) >= 2
        ? '+' + over(odds).toFixed()
        : under(odds).toFixed();
    return americanOdds;
  } else {
    return odds;
  }
};

/**
 * convert amrican odd to decimal value
 */
const getAmricanOdd = odd => {
  const positiveValue = odd => odd / 100 + 1;
  const negativeValue = odd => -100 / odd + 1;
  const decimalPrice = odd > 0 ? positiveValue(odd) : negativeValue(odd);
  return decimalPrice;
};

/**
 * Method to get american button label
 */
const getNameForAmericanButton = (selection, americanMarketType) => {
  let name = selection.name && selection.name.match(/[\d.()+-]+/);
  if (Array.isArray(name)) {
    name = name[0];
  }
  if (americanMarketType === Constants.TOTAL && name) {
    name =
      selection.typeRef === Constants.OVER
        ? `${Translations.get('market.O')} ` + name
        : `${Translations.get('market.U')} ` + name;
  }
  return name;
};

/**
 * Method to get date format for american layout
 */
const getDate = (scheduledStart, timeZone) => {
  if (timeZone === 'US') {
    const date = moment(scheduledStart);
    const day = dateOrdinal(date.date());
    const hour = date.format('h');
    const minutes = date.format('mm');
    const month = Translations.get(`month.${date.format('MMM')}`);
    const year = Translations.get(`year.${date.format('YYYY')}`);
    const time =
      `${hour}:${minutes} ` +
      (date.hour() >= 12
        ? Translations.get('time.pm')
        : Translations.get('time.am'));
    return ` ${month}/${day}/${year} ${time} `;
  } else {
    const date = moment(scheduledStart);
    const day = dateOrdinal(date.date());
    const hour = date.format('h');
    const minutes = date.format('mm');
    const month = Translations.get(`month.${date.format('MMM')}`);
    const time =
      `${hour}:${minutes} ` +
      (date.hour() >= 12
        ? Translations.get('time.pm')
        : Translations.get('time.am'));
    return `${day} ${month}, ${time}`;
  }
};

//FIXME
const dateOrdinal = d =>
  d +
  (31 === d || 21 === d || 1 === d
    ? Translations.get('number.st')
    : 22 === d || 2 === d
    ? Translations.get('number.nd')
    : 23 === d || 3 === d
    ? Translations.get('number.rd')
    : Translations.get('number.th'));

export { getOdds, getNameForAmericanButton, getDate, getAmricanOdd };
