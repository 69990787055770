import { GREYS } from 'UI/globals/colours';
import React from 'react';

/**
 *
 * @param {string} text
 * @param {string} highlight
 * @returns highlighted text
 */
export function getHighlightedText(text, highlight) {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { fontWeight: 'bold', color: GREYS.black }
              : {}
          }
        >
          {part}
        </span>
      ))}{' '}
    </span>
  );
}
